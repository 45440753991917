.login-page {
  /* background-image: url('../../../public/images/bg.jpg'); */
  height: 100vh;
  overflow: hidden;
  align-items: center;
}

.login-form-container {
  background-color: #fff;
  border-radius: 24px;
  padding: 36px;
  width: 569px;
}

.logo {
  margin-top: 60px;
  margin-bottom: 40px;
}

.login-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 110%;
  color: #101828;
}

.login-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #6123FF;
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
}

.error-text {
  color: #B40020;
  margin-left: 16px;
  margin-top: 8px;
}

.column-center {
  align-items: center;
}