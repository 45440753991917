.navigation {
  padding: 30px 46px;
  box-sizing: border-box;
  color: #fff;
  position: relative;
}

/* .logo-link {
  margin: 0px 0px 20px 16px;

} */


.nav-item {
  height: 44px;
  align-items: center;
  padding-left: 18px;
  color: #fff;
  text-decoration: none;
  transition: .6s;
  border-radius: 4px;
  margin-bottom: 4px;
  opacity: 0.6;
  overflow: hidden;
  word-wrap: none;
  white-space: nowrap;
  cursor: pointer;
}

.navigation-light .nav-item,
.navigation-light button {
  color: #1B1B26;
}

.navigation-light .mobile-menu .nav-item,
.navigation-light .mobile-menu button {
  color: #ffffff;
}

.nav-item-collapsed {
  width: 60px;
}

.nav-item svg {
  margin-right: 14px;
  flex-shrink: 0;
  width: 24px;
}

.nav-item.active,
.nav-item:hover {
  opacity: 1;
}

.navigation-light .mobile-menu .nav-item {
  opacity: 1;
}

.navigation-light .mobile-menu .nav-item.active,
.navigation-light .mobile-menu .nav-item:hover {
  opacity: 0.6;
}

.nav-reparator {
  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  opacity: 0.6;
}


.nav-user {
  align-items: center;
  padding-left: 16px;
  margin-bottom: 20px;
}

.nav-user .email {
  font-size: 13px;
  opacity: 0.6;
}

.nav-user .name {
  margin-bottom: 6px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  margin-right: 14px;
  background-color: #5F5F68;
  display: flex;
  align-items: center;
  justify-content: center;
}


.avatar img {
  width: 100%;
}

.collapse-menu {
  position: absolute;
  right: 0;
  bottom: 240px;
  cursor: pointer;
}

.collapse-menu-icon {
  position: absolute;
  right: 6px;
  bottom: 40px;
  transition: .4s;
}

.collapse-menu-icon-collapsed {
  transform: rotate(180deg);
}

.bell-button {
  width: 44px;
  height: 44px;
  border-radius: 44px;
  justify-content: center;
  /* margin-left: 64px; */
  border: 0;
  cursor: pointer;
}

.accout-button {
  height: 44px;
  border-radius: 44px;
  justify-content: center;
  margin-left: 16px;
  border: 0;
  color: #fff;
  cursor: pointer;
}

.accout-button-avatar {
  width: 32px;
  height: 32px;
  background-color: #F4F4F733;
  border-radius: 32px;
  margin-right: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation-light .accout-button-avatar {
  background-color: #acacac83;
}

.accout-button-avatar img {
  width: 100%;
}

.accout-button-name {
  margin-right: 12px;
}

.profile-picture {
  width: 80px;
  height: 80px;
  background-color: #B8B8CB33;
  border-radius: 80px;
  margin-right: 24px;
  overflow: hidden;
}

.profile-picture img {
  width: 100%;
}


.mobile-menu {
  position: fixed;
  background-color: #0d0d1ede;
  color: #fff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  padding: 28px;
  z-index: 10;
  backdrop-filter: blur(10px);

}

.mobile-menu .nav-item {
  padding: 0;
}

.mobile-menu .accout-button {
  margin-left: 0;
  margin-top: 20px;
}

@media (max-width: 1024px) {
  .navigation {
    padding: 20px 16px;
  }

  .accout-button {}
}