.dashboard-page {
  padding: 32px;
  color: #fff;
}

.dashboard-widget {
  width: 50%;
  margin: 0 8px;
  border-radius: 24px;
  padding: 32px;
}


.dashboard-widget h2 {
  font-weight: 600;
  margin-bottom: 16px;
}

.dashboard-widget-big-num {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 80px;
  line-height: 100%;
  letter-spacing: 1px;
  color: #00E65C;
}

.dashboard-widget-target-progress {
  position: relative;
  height: 12px;
  border-radius: 12px;
  width: 100%;
  margin-left: 90px;
  background-color: #ffffff30;
  margin-top: 50px;
}


.dashboard-widget-target-progress .progress {
  background-color: #00E65C;
  height: 12px;
  border-radius: 12px;
}

.dashboard-widget-target-progress p {
  position: absolute;
  top: -60px;
}

.target-img {
  position: absolute;
  top: -40px;
  margin-left: -70px;
}

.activity-item {
  margin-bottom: 24px;
}

.activity-item-stars-count {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 31.9367px;
  line-height: 100%;
  letter-spacing: -4px;
  background: radial-gradient(98.91% 98.91% at 17.61% 19.13%, #FFE600 0%, #FFF500 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    , linear-gradient(104.81deg, #FBF563 34.38%, rgba(251, 245, 99, 0) 102.89%), #FFFFFF;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  padding: 0px 5px 0px 0px;
}

.activity-item-icon {
  background: linear-gradient(218.82deg, rgba(255, 255, 255, 0.1) 7.43%, rgba(255, 255, 255, 0.05) 90.16%);
  margin-right: 16px;
  width: 48px;
  height: 48px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activity-item-time {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  opacity: 0.7;

}

.activity-item-title {}


.roadmap {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 30px;
  position: relative;
}

.dased-frame {
  position: absolute;
  z-index: 3;
  height: 360px;
}

.dased-frame1 {
  top: 138px;
  left: 39px;
  width: calc(50% - 47px);
}

.dased-frame2 {
  top: 133px;
  right: 30px;
  width: calc(50% - 30px);
}

.dased-frame3 {
  top: 503px;
  right: 30px;
  width: calc(100% - 50px);
  height: 500px;
}

.dased-frame4 {
  top: 30px;
  right: 340px;
  width: 190px;
  height: 45px;
}

.intro-popup {
  background: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  width: 356px;
  z-index: 5;
  position: absolute;
}

.intro-popup-arrow {
  position: absolute;
  top: 20px;
  left: -9px;
}

.intro-popup-arrow-2 {
  transform: rotate(180deg);
  left: 355px;
}

.intro-popup-arrow-3 {
  transform: rotate(270deg);
  left: 270px;
  bottom: -246px;
}

.intro-popup-arrow-4 {
  transform: rotate(90deg);
  left: 150px;
  top: -15px;
}

.close-icon {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: #E7E7EE;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.intro-popup-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  margin-top: 12px;
  margin-bottom: 12px;
  /* identical to box height, or 29px */


  /* Black/100 */

  color: #1B1B26;
}

.intro-popup-txt {
  font-size: 16px;
  line-height: 130%;
  color: #1B1B26;
}

.n-off {
  color: #1B1B26;

  opacity: 0.5;
}

.intro-popup-1 {
  top: 153px;
  left: calc(50% + 10px)
}

.intro-popup-2 {
  top: 153px;
  right: calc(50% + 50px)
}

.intro-popup-3 {
  top: 220px;
  left: calc(0% + 100px)
}

.intro-popup-4 {
  top: 90px;
  right: calc(0% + 240px);
}


.intro-popup-bottom {
  margin-top: 24px;
}

.tutorial-overlay {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #202635a3;
  top: 0;
  left: 0;
  z-index: 2;
}

/* width */
.roadmap::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.roadmap::-webkit-scrollbar-track {
  background: transparrent;
}

/* Handle */
.roadmap::-webkit-scrollbar-thumb {
  background: #88888840;
  border-radius: 8px;
}

/* Handle on hover */
.roadmap::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.activity-history-datetime {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  width: 120px;

  text-align: right;

  color: #76767F;
  white-space: nowrap;
}

.stars-reward {
  position: fixed;
  top: calc(50% - 340px);
  left: 0;
  z-index: 10000;
  transition: .4s;
  animation-timing-function: cubic-bezier(0.1, -0.6, 0.62, 1.42);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reward-glow {
  position: fixed;
  top: 0px;
  left: calc(50% - 456px);
  z-index: 10001;
  transition: .4s;
}

.stars-reward-hidden {
  opacity: 0;
  top: 100vh;
}

.reward-glow-hidden {
  opacity: 0;
  top: -283px;
}




/* progress */

.timeline-progress-bar {
  background: #443174;
  border-radius: 2px;
  width: 412px;
  height: 8px;
  margin: 0px 10px;
  position: relative;
}

.timeline-progress-content {
  background: linear-gradient(90deg, #AD31F8 0%, #7910FF 100%);
  border-radius: 2px;
  height: 8px;
  transition: 1s;
}

.timeline-progress-indicator {
  position: absolute;
  top: 184px;
  left: 100px;
  transition: 1s;
  z-index: 2;
}

.milestone-score {
  padding: 4px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 6px;
}

.milestone-score p {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;
  opacity: 0.7;
}

.current-score {
  padding: 8px 18px;
  background: #6123FF;
  border-radius: 8px;
  position: absolute;
}

.milestone-score.current-score {
  position: relative;
}

.current-score p {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 120%;
  text-align: center;
  color: #FFFFFF;
}

.milestone-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 120%;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.milestone-description {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */


  color: #FFFFFF;

  opacity: 0.5;
}

.achivement-image-locked {
  filter: blur(5px) saturate(0);
  opacity: 0.5;
}

.achivement-image {
  transition: 1s;
  position: absolute;
  top: -210px;
  left: -87px;
  width: 248px;
}

.level-label-wrapper {
  position: absolute;
  top: -130px;
  left: -210px;
  height: 88px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stars-reward .level-label-wrapper {
  position: relative;
  top: unset;
  left: unset;
  height: 88px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-label {
  height: 75px;
  border-radius: 80px;
  background-color: #443174;
  padding: 0px 90px 0px 36px;
  position: relative;
}

.level-label-locked {
  background-color: #ffffff25;
  padding: 0px 36px 0px 36px;
}

.level-label p {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 90%;
  margin-left: 16px;
  text-align: center;
}

.level-label img {
  position: absolute;
  right: -90px;
  top: -85px;
  width: 214px;
}

.flip {
  backface-visibility: visible !important;
  animation: flip 2s ease;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateY(0);
    animation-timing-function: ease-out;
  }

  40% {
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    animation-timing-function: ease-out;
  }

  50% {
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    animation-timing-function: ease-in;
  }

  80% {
    transform: perspective(400px) rotateY(360deg) scale(.95);
    animation-timing-function: ease-in;
  }

  100% {
    transform: perspective(400px) scale(1);
    animation-timing-function: ease-in;
  }
}

@media (max-width: 1024px) {
  .dashboard-page {
    padding: 16px;
    position: relative;
  }

  .mob-column {
    display: flex;
    flex-direction: column;
  }

  .dashboard-widget {
    width: calc(100% - 20px);
    border-radius: 16px;
    padding: 24px;
    margin-bottom: 30px;
  }


  .dashboard-widget h2 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .dashboard-widget-big-num {
    font-size: 40px;
    margin-top: 10px;
  }

  .target-img {
    position: absolute;
    width: 100px;
    height: 100px;
    top: -18px;
    margin-left: -40px;
  }

  .timeline-progress-bar {
    width: 340px
  }

  .timeline-step {
    position: relative;
  }

  .milestone-title,
  .milestone-description {
    margin-left: -115px;
    text-align: center;
  }

  .milestone-score-container {
    max-width: 300px;
    margin-left: -115px;
    justify-content: center;
  }

  .timeline-step:first-child .milestone-title,
  .timeline-step:first-child .milestone-description {
    margin-left: 0px;
    text-align: left;
  }

  .timeline-step:first-child .milestone-score-container {
    max-width: 300px;
    margin-left: 0px;
    justify-content: flex-start;
  }

  .dashboard-widget-target-progress {
    height: 8px;
    border-radius: 8px;
    width: 100%;
    margin-left: 60px;
    background-color: #ffffff30;
    margin-top: 30px;
  }


  .dashboard-widget-target-progress .progress {
    background-color: #00E65C;
    height: 8px;
    border-radius: 8px;
  }

  .dashboard-widget-target-progress p {
    position: absolute;
    top: -35px;
    font-size: 13px;
    /* text-align: center; */
    /* width: 100%; */
  }

  .activity-item-time {
    font-size: 11px;
  }

  .activity-item,
  .activity-history-activity-name {
    font-size: 14px;
  }

  .activity-history-datetime {
    width: 100px;
    white-space: normal;
    font-size: 11px;
  }

  .activity-item-icon {
    width: 24px;
    height: 24px;
  }


  .dased-frame1 {
    top: 7px;
    left: 16px;
    width: calc(100% - 36px);
    height: 190px;
  }

  .dased-frame2 {
    top: 223px;
    right: 30px;
    width: calc(100% - 50px);
    height: 230px;
  }

  .dased-frame3 {
    top: 503px;
    right: 30px;
    width: calc(100% - 50px);
    height: 500px;
  }

  .dased-frame4 {
    top: -70px;
    right: 0px;
    width: 50px;
    height: 45px;
  }

  .intro-popup-arrow {
    position: absolute;
    top: -14px;
    left: 43px;
    transform: rotate(90deg);
  }

  .intro-popup-arrow-2 {
    transform: rotate(270deg);
    left: 315px;
    top: 226px;
  }

  .intro-popup-arrow-3 {
    transform: rotate(270deg);
    left: 90px;
    top: 270px;
    bottom: unset;
  }

  .intro-popup-arrow-4 {
    transform: rotate(180deg);
    left: 355px;
    top: 15px;
  }

  .intro-popup-1 {
    top: 203px;
    left: 30px;
  }

  .intro-popup-2 {
    top: -37px;
    left: 10px;
  }

  .intro-popup-3 {
    top: 220px;
    left: calc(0% + 100px)
  }

  .intro-popup-4 {
    top: -70px;
    right: 60px;
  }


}

@media (max-width: 768px) {
  .intro-popup-3 {
    top: 220px;
    left: 20px
  }

  .intro-popup-4 {
    top: -70px;
    right: 60px;
    width: 80%;
  }
}