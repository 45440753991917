.card-text-content {
  color: #fff;
  background: linear-gradient(180deg, #321A6E 0%, #201835 100%);
  padding: 50px;
  width: 35%;
}

.card-text-content .line {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  margin-bottom: 19px;
}

.card-text-content .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 28px;
}

.card-text-content .text {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 46px;
  /* or 17px */


  color: #C4C4FF;
}

.card-text-content .button {
  padding: 10px 30px;
  background: #6123FF;
  box-shadow: 0px 4.57143px 18.2857px rgba(143, 99, 255, 0.16);
  border-radius: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 11.4286px;
  line-height: 120%;
  /* identical to box height, or 14px */

  text-align: center;

  color: #F3F3F3;
  text-transform: none;
}

.template-preview-detatl {
  font-size: 18px;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.template-preview-detatl:last-of-type {
  margin-bottom: 20px;
}

.template-preview-detatl span {
  font-weight: 400;
}

.template-wrapper {
  position: relative;
}

.template-wrapper::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

}

.template-preview-box {
  border-radius: 4px;
  margin: 8px 19px 8px 8px;
  padding: 10px;
  border: 1px solid #eee;
  box-shadow: 2px 2px 3px #aaaaaa6b;
  position: relative;
}

.column.template-preview-box {
  height: calc(100% - 74px)
}

.template-preview-subject {
  font-size: 18px;
  font-weight: 600;
}

.sender-and-email {
  font-weight: 600;
  margin-bottom: 8px;
}

.sender-and-email br {
  display: none;
}

.template-actions {
  margin-bottom: 20px;
}

.template-preview-wrapper {
  width: 100%;
  height: calc(100% - 54px);
  overflow: auto;
}

.sender-avatar {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background-color: #abf;
  margin-right: 20px;
  flex-shrink: 0;
}

.line {
  font-family: 'Satoshi';
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 23px;
}

.title {
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.tip-block-arrow {
  width: 30px;
  height: 30px;
  border-top: 1px solid #6D4AFF55;
  border-left: 1px solid #6D4AFF55;
  position: absolute;
  top: -15px;
  left: 50px;
  transform: rotate(45deg);
  background-color: rgba(255, 255, 255, 0.88);
  backdrop-filter: blur(10px);
}

@media (max-width: 480px) {
  .template-preview-subject {
    font-size: 16px;
    font-weight: 600;
  }

  .sender-and-email {
    /* font-weight: 500; */
    font-size: 15px;
  }

  .mob-hide {
    display: none;
  }

}