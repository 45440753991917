.chat-container {
  overflow: hidden;
  overflow-y: auto;
  transition: 500ms;
  padding: 0px 10px 0px 0px;
}

.chat-container-box {
  display: flex;
  min-height: calc(-280px + 100vh);
  justify-content: flex-end;
}

.chat-container::-webkit-scrollbar {
  width: 10px;
}

.chat-container::-webkit-scrollbar-track {
  background: transparrent;
}

.chat-container::-webkit-scrollbar-thumb {
  background: #88888840;
  border-radius: 8px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-message {
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
}

.chat-message-type-1 {
  justify-content: flex-end;
}

.chat-message-type-2 {
  justify-content: flex-start;
}

.chat-message-type-1 .chat-message-body {
  background-color: #fff;
  border: 1px solid #76767F33;
}

.chat-message-type-2 .chat-message-body {
  background-color: #6123FF14;
}

.chat-message-body {
  border-radius: 45px;
  padding: 16px 24px;
  line-height: 21px;
}