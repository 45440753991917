.App {
  text-align: center;
}

#root {
  background: radial-gradient(81.01% 92.09% at 66.77% 32.09%, #0B1D63 0%, #0C0726 61.98%, #09051E 86.98%);
  min-height: 100vh;
}

.app-layout {
  display: flex;
}

.text-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  border-radius: 6px;
  padding: 4px 10px;
  transition: 300ms;
}

.text-button:hover {
  background-color: #ffffff20;
}

.app-layout-light {
  min-height: 100vh;
}

.gradient1 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(64, 32, 145, 0) 193.18%);
}

.navigation-light .gradient1 {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) -36.36%, rgba(64, 32, 145, 0.1) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.16) 0%, rgba(64, 32, 145, 0) 193.18%);
  ;
}



.gradient2 {
  background: linear-gradient(180deg, rgba(72, 43, 144, 0.47) 0%, rgba(12, 24, 83, 0) 100%);
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.row-center {
  align-items: center;
}

.spacer {
  display: flex;
  flex: 1;
}

.white-box {
  padding: 24px 32px;
  border-radius: 10px;
  margin-bottom: 16px;
  background-color: #fff;
}

.white-box h2 {
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #76767F;
  margin-bottom: 16px;
}

.highlight-text {
  background-color: #DFD3FF;
  color: #6123FF;
}

.drawer-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
  line-height: 110%;
  color: #1B1B26;
}


.text-shrink-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-all;
  flex-shrink: 0;
}

.text-shrink-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  flex-shrink: 0;
}

.text-shrink-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  flex-shrink: 0;
}