.training-items {
  flex-wrap: wrap;
  justify-content: flex-start;
}

.training-item {
  margin: 10px 5px;
  width: 283px;
  height: 330px;
  background: #0179FF;
  border-radius: 12px;
  box-shadow: 0px 29px 35px rgba(1, 121, 255, 0.12);
  box-sizing: border-box;
  padding: 24px;
  color: #fff;
}

.training-item-cat {
  font-weight: 600;
  font-size: 12px;
  margin-right: 8px;
}

.training-item-title {
  font-family: 'Satoshi';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 116%;
  margin-top: 15px;
  z-index: 2;
}

.training-item-stars {
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  margin-top: 16px;
  z-index: 2;
}

.training-item-image {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1;
}


.training-item-deadline {
  font-weight: 400;
  font-size: 13px;
  z-index: 2;
}

.training-item-deadline-done {
  background-color: #00BC62;
  border-top: 3px solid #fff;
  padding: 20px 24px;
  margin: -24px;
  border-radius: 0px 0px 8px 8px;
}

.tabs {
  border-bottom: 1px solid #DFD3FF;
  margin-bottom: 0px;
  margin-top: 48px;
}

.tab {
  height: 48px;
  padding: 0px 20px;
  border-radius: 8px 8px 0px 0px;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.4s;
}

.tab-active {
  background-color: #F7F4FF;
  font-weight: 600;
  color: #6123FF;
}

.tab:hover {
  background-color: #F7F4FF;
  color: #6123FF;
}

.badge {
  background-color: #F4F5F9;
  color: #101828;

  font-weight: 600;
  font-size: 12px;
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 20px;
  text-align: center;
  line-height: 22px;
  margin-left: 8px;
}

.tab-active .badge {
  background-color: #6123FF;
  color: #fff;
}


@media (max-width: 768px) {
  .training-item-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    margin-top: 14px;
  }

  .training-item {
    width: calc(50% - 10px);
  }
}